<template>
  <v-container>
    <page-title
      previous-location="/teachers/courses?selectedTab=0"
      :page-title="!$language ? 'Create Course' : 'কোর্স তৈরী করুন'"
      class="mt-5"
    ></page-title>
    <m-course-create-p
      :partner-code="partner_code"
      @done="$router.go(-1)"
    ></m-course-create-p>
  </v-container>
</template>

<script>
import _ from "lodash";
import { required } from "@ecf/global/validators";
import MCourseCreateP from "#ecf/course/components/MCourseCreateP";
export default {
  name: "CourseCreatePage",
  components: { MCourseCreateP },
  props: {
    saving: Boolean,
    partner_code: String
  },
  data() {
    return {
      savingCourse: false,
      formData: {
        course_title: undefined,
        course_key: undefined,
        course_description: undefined,
        course_how_it_works: undefined,
        course_syllabus: undefined,
        quiz_enabled: true
      },
      rules: { required: required() }
    };
  },
  methods: {
   
  }
};
</script>
