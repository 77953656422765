var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mt-2" },
    [
      _c("submission-alert-message", {
        ref: "banner",
        staticClass: "py-3",
        staticStyle: { "max-width": "1100px" },
        attrs: { "should-scroll-to-top": "", message: _vm.alertMessage },
        model: {
          value: _vm.showFormAlertMessage,
          callback: function ($$v) {
            _vm.showFormAlertMessage = $$v
          },
          expression: "showFormAlertMessage",
        },
      }),
      _c(
        "v-card",
        { staticClass: "m-light-card" },
        [
          _c("course-details-form-p", {
            ref: "form",
            attrs: {
              valid: _vm.valid,
              isClickSaveButton: _vm.saveButtonClick,
              "should-show-course-material-alert":
                _vm.shouldShowCourseMaterialAlert,
              "should-auto-focus": _vm.shouldAutoFocus,
            },
            on: {
              "update:valid": function ($event) {
                _vm.valid = $event
              },
              closeAlertMessage: _vm.clickInactiveSaveButton,
            },
            model: {
              value: _vm.courseData.courseDetails,
              callback: function ($$v) {
                _vm.$set(_vm.courseData, "courseDetails", $$v)
              },
              expression: "courseData.courseDetails",
            },
          }),
          !_vm.hideBtn
            ? _c(
                "v-container",
                { staticClass: "pr-10" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "m-rounded-button",
                        {
                          staticClass: "btn-outlined",
                          attrs: {
                            outlined: "",
                            color: "red accent-3",
                            "data-qa": "cancel-button",
                          },
                          on: { click: _vm.cancel },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("cancel"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(!_vm.$language ? "Cancel" : "বাতিল করুন") +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "m-rounded-button",
                        {
                          staticClass: "ml-2 btn-outlined",
                          attrs: { depressed: "", loading: _vm.creating },
                          on: {
                            click: function ($event) {
                              _vm.isFormDataValid ? _vm.save() : _vm.showAlert()
                            },
                          },
                        },
                        [
                          _c("m-icon", { attrs: { left: "" } }, [
                            _vm._v("save_alt"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(!_vm.$language ? "Save" : "সেভ করুন") +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }