var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": "/teachers/courses?selectedTab=0",
          "page-title": !_vm.$language ? "Create Course" : "কোর্স তৈরী করুন",
        },
      }),
      _c("m-course-create-p", {
        attrs: { "partner-code": _vm.partner_code },
        on: {
          done: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }