<template>
  <v-container class="mt-2">
    <submission-alert-message
      should-scroll-to-top
      ref="banner"
      class="py-3"
      v-model="showFormAlertMessage"
      :message="alertMessage"
      style="max-width: 1100px"
    ></submission-alert-message>
    <v-card class="m-light-card">
      <course-details-form-p
        ref="form"
        v-model="courseData.courseDetails"
        :valid.sync="valid"
        :isClickSaveButton="saveButtonClick"
        :should-show-course-material-alert="shouldShowCourseMaterialAlert"
        :should-auto-focus="shouldAutoFocus"
        @closeAlertMessage="clickInactiveSaveButton"
      ></course-details-form-p>
      <v-container v-if="!hideBtn" class="pr-10">
        <v-row justify="end">
          <m-rounded-button
            outlined
            color="red accent-3"
            data-qa="cancel-button"
            class="btn-outlined"
            @click="cancel"
          >
            <v-icon left>cancel</v-icon>
            {{ !$language ? "Cancel" : "বাতিল করুন" }}
          </m-rounded-button>
          <m-rounded-button
            depressed
            :loading="creating"
            class="ml-2 btn-outlined"
            @click="isFormDataValid ? save() : showAlert()"
          >
            <m-icon left>save_alt</m-icon>
            {{ !$language ? "Save" : "সেভ করুন" }}
          </m-rounded-button>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import CourseDetailsFormP from "#ecf/course/components/CourseDetailsFormP";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage.vue";

export default {
  name: "MCourseCreate",
  components: { CourseDetailsFormP, SubmissionAlertMessage },
  props: {
    hideBtn: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      default: () => ({}),
    },
    partnerCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      creating: false,
      courseData: {
        courseDetails: { ...this.course },
        batches: [],
      },
      saveButtonClick: false,
      valid: false,
      shouldShowCourseMaterialAlert: false,
      shouldAutoFocus: false,
      validityDialogue: false,
      showFormAlertMessage: false,
    };
  },

  computed: {
    isFormDataValid() {
      return (
        this.isCourseDescriptionValid &&
        this.isSyllabusValid &&
        this.valid &&
        this.isCourseMaterialValid
      );
    },

    courseMaterialCount() {
      let materials = this.courseData.courseDetails.course_materials;
      return (
        this.isNumberOrNaN(parseInt(materials.video_lectures)) +
        this.isNumberOrNaN(parseInt(materials.live_classes)) +
        this.isNumberOrNaN(parseInt(materials.quizzes)) +
        this.isNumberOrNaN(parseInt(materials.lecture_notes)) +
        this.isNumberOrNaN(parseInt(materials.projects)) +
        this.isNumberOrNaN(parseInt(materials.assignments)) +
        this.isNumberOrNaN(parseInt(materials.exams))
      );
    },

    isCourseDescriptionValid() {
      return (
        this.courseData.courseDetails.course_description != undefined &&
        this.removeHtmlTag(this.courseData.courseDetails.course_description)
          .length >= 40 &&
        this.removeHtmlTag(this.courseData.courseDetails.course_description)
          .length <= 60000
      );
    },

    isSyllabusValid() {
      return (
        this.courseData.courseDetails.syllabus != undefined &&
        this.removeHtmlTag(this.courseData.courseDetails.syllabus).length >=
          40 &&
        this.removeHtmlTag(this.courseData.courseDetails.syllabus).length <=
          60000
      );
    },

    isCourseMaterialValid() {
      return (
        this.courseData.courseDetails.course_type !== "Public" ||
        this.courseMaterialCount != 0
      );
    },

    alertMessage() {
      return this.$language
        ? "সকল বাধ্যতা মূলক তথ্য প্রদান করুন"
        : "Please fill out all mandatory fields";
    },
  },

  watch: {
    isFormDataValid(value) {
      if (value) this.showFormAlertMessage = false;
    },
  },

  methods: {
    clickInactiveSaveButton() {
      this.saveButtonClick = false;
    },
    async cancel() {
      await this.$router.replace({
        name: "courses",
      });
    },
    isNumberOrNaN(inputValue) {
      return isNaN(inputValue) ? 0 : inputValue;
    },

    removeHtmlTag(htmlData) {
      let div = document.createElement("div");
      div.innerHTML = htmlData;
      return div.textContent || div.innerText || "";
    },

    async save() {
      // if (this.isFormDataValid) {
      // this.saveButtonClick = true;
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      if (this.creating) return;
      try {
        this.creating = true;
        if (this.courseData.courseDetails.category === "Others")
          this.courseData.courseDetails.subcategories = [
            this.courseData.courseDetails.additionalSubCategory,
          ];
        if (
          "image" in this.courseData.courseDetails &&
          this.courseData.courseDetails.image.imageURL !== null
        ) {
          // quick fix as I didn't want to touch image-input which is used by other components
          this.courseData.courseDetails.image.imageURL += ".png";
        } else {
          delete this.courseData.courseDetails.image;
        }
        if (
          "about_video" in this.courseData.courseDetails &&
          this.courseData.courseDetails.about_video == ""
        ) {
          delete this.courseData.courseDetails.about_video;
        }
        if (
          "facebook_group" in this.courseData.courseDetails &&
          this.courseData.courseDetails.facebook_group == ""
        ) {
          delete this.courseData.courseDetails.facebook_group;
        }
        for (var key in this.courseData.courseDetails.course_materials) {
          if (this.courseData.courseDetails.course_materials[key] === 0) {
            this.courseData.courseDetails.course_materials[key] = "00";
          } else {
            this.courseData.courseDetails.course_materials[key] = String(
              this.courseData.courseDetails.course_materials[key]
            ).padStart(2, 0);
          }
        }
        await this.$store.dispatch("course/create", {
          courseData: this.courseData.courseDetails,
          partner_code: this.partnerCode,
        });
        this.$emit("done");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.creating = false;
      }
      // }
    },

    async showAlert() {
      this.shouldShowCourseMaterialAlert = !this.isCourseMaterialValid;
      this.shouldAutoFocus = !this.isFormDataValid;
      this.saveButtonClick = true;
      // this.$root.$emit('error', [undefined, 'Mandetory fields must be filled, for Public courses Course Materials are also mandetory']);

      await this.$refs.form.validate();
      if (!this.showFormAlertMessage) this.showFormAlertMessage = true;
      else {
        await this.$refs.banner.flash();
      }
    },
  },
};
</script>
