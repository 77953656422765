import { render, staticRenderFns } from "./CourseCreatePage.vue?vue&type=template&id=265f3bc6"
import script from "./CourseCreatePage.vue?vue&type=script&lang=js"
export * from "./CourseCreatePage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/fluent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('265f3bc6')) {
      api.createRecord('265f3bc6', component.options)
    } else {
      api.reload('265f3bc6', component.options)
    }
    module.hot.accept("./CourseCreatePage.vue?vue&type=template&id=265f3bc6", function () {
      api.rerender('265f3bc6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "portals/teachers-portal/src/routes/course/CourseCreatePage.vue"
export default component.exports